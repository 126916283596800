import { MayNull } from '@apeiron/temp'
import { ROUTE } from '@src/constants/route'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import { useEffect } from 'react'

const useRedirect = (path: MayNull<ROUTE | string>) => {
  const router = useRouter()

  useEffect(() => {
    if (R.isNotNil(path)) {
      const { asPath } = router

      const query = R.split('?', asPath)[1]

      const url = query ? `${path}?${query}` : path

      router.replace(url)
    }
  }, [path])
}

export default useRedirect
