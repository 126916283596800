import { ApeironLinkButton } from '@apeiron/temp'
import { MuiLoading, usePath } from '@apeiron/ui'
import { Box, styled, Typography } from '@mui/material'
import { WhiteList } from '@src/constants/page'
import { DeprecateMap } from '@src/constants/redirect'
import { ROUTE } from '@src/constants/route'
import useRedirect from '@src/hooks/common/router/useRedirect'
import { NextPage } from 'next'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 80px 40px;
  width: 100%;
`

const Text = styled(Typography)`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  white-space: pre-line;
`

const BackButton = styled(ApeironLinkButton)`
  background-color: #005bd7;
  border-radius: 4px;
  padding: 10px 20px;
  width: 80px;
`

const NotFoundPage: NextPage<Props> = () => {
  const { t } = useTranslation()

  const { cleanPath } = usePath(WhiteList)

  const redirectPath = DeprecateMap[cleanPath]

  useRedirect(redirectPath)

  return (
    <Container>
      {R.isNotNil(redirectPath) ? (
        <MuiLoading loading />
      ) : (
        <>
          <Text>{t('common.page.not_found')}</Text>
          <BackButton url={ROUTE.HOME}>{t('common.button.back')}</BackButton>
        </>
      )}
    </Container>
  )
}

type Props = {}

export default NotFoundPage
